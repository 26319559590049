import { calculaAliqIcmsRedBCEntrada } from '../Procedures/CalculaAliqIcmsRedBCEntrada';

/**
 * @solidus - CalcValSubstituicao
 */

export function calculaValorST(
  valVendaVarejo: number,
  valAliqIcmsEntrada: number,
  perRedBCEntrada: number,
  valAliqIcmsEntradaNCM: number,
  perRedBCEntradaNCM: number,
  valAliqIcmsSaida: number,
  perRedBCSaida: number,
  perPautaIVANCM: number,
  valPautaIVA: number,
  valCustoEmbalagemSemIPISTFrete: number,
  tipoTribEntrada: number,
  tipoTribSaida: number,
  isVedaCredito: boolean,
  isRegST: boolean,
  isFornIntrIcms: boolean,
  isFornSimples: boolean,
  isInterestadual: boolean,
  isNovoCalc: boolean,
  tipoRet: number,
  valSTCredito: number,
): number {
  let vendaVarejo = 0;
  /**
   * 0 - ValSubst
   * 1 - BC ST
   * 2 - Venda Varejo
   */

  const infoTribEntrada = calculaAliqIcmsRedBCEntrada(
    valAliqIcmsEntrada,
    perRedBCEntrada,
    valAliqIcmsEntradaNCM,
    perRedBCEntradaNCM,
    valAliqIcmsSaida,
    perRedBCSaida,
    perPautaIVANCM,
    valPautaIVA,
    tipoTribEntrada,
    isVedaCredito,
    isRegST,
    isFornIntrIcms,
    isFornSimples,
    isInterestadual,
  );

  valAliqIcmsEntrada = infoTribEntrada.valAliqIcmsEntrada;
  perRedBCEntrada = infoTribEntrada.perRedBCEntrada;
  const valBCSTEntrada = parseFloat(
    (valCustoEmbalagemSemIPISTFrete * (1 - perRedBCEntrada / 100)).toFixed(4),
  );

  if (isFornIntrIcms && tipoTribEntrada === 3) {
    valSTCredito = 0;
  } else if (isFornSimples && !isInterestadual) {
    valSTCredito = valBCSTEntrada * (valAliqIcmsSaida / 100);
  } else {
    valSTCredito = valBCSTEntrada * (valAliqIcmsEntrada / 100);
  }
  let valBCSTSaida = parseFloat(
    (valVendaVarejo * (1 - perRedBCSaida / 100)).toFixed(4),
  );
  if (tipoRet === 1 && !isNovoCalc) {
    return valBCSTSaida > 0 ? valBCSTSaida : 0;
  }

  let valSTDebito = valBCSTSaida * (valAliqIcmsSaida / 100);
  if (isNovoCalc) {
    vendaVarejo = valVendaVarejo - valSTCredito;
    vendaVarejo /=
      1 - (valAliqIcmsSaida - valAliqIcmsSaida * (perRedBCSaida / 100)) / 100;

    if (tipoRet === 2) {
      return vendaVarejo > 0 ? vendaVarejo : 0;
    }
    valBCSTSaida = vendaVarejo - valBCSTSaida * (perRedBCSaida / 100);
    if (tipoRet === 1) {
      return valBCSTSaida > 0 ? valBCSTSaida : 0;
    }

    valSTDebito = valBCSTSaida * (valAliqIcmsSaida / 100);
    if (valSTDebito < 0) valSTDebito = 0;
  }

  let soma = 0;

  if (tipoTribSaida === 3 && valSTDebito !== 0) {
    soma = parseFloat((valSTDebito - valSTCredito).toFixed(4));
  }
  if (soma < 0) soma = 0;
  return parseFloat(soma.toFixed(2));
}
