/**
 * @Solidus CalculaTabelaLiquido
 * @param valTabela  - Tabela
 * @param valDesconto - Val_Desc
 * @param perDesconto - Per_Desc
 * @param valAcrescimo - Val_Acr
 * @param perAcrescimo - Per_Acr
 * @param isAcrescimoBruto - Acr_Bruto
 */

export function calculaTabelaLiquido(
  valTabela: number,
  valDesconto: number,
  perDesconto: number,
  valAcrescimo: number,
  perAcrescimo: number,
  isAcrescimoBruto: boolean,
): number {
  let acrescimo = 0;
  let desconto = 0;
  desconto = valDesconto + (valTabela * perDesconto) / 100;

  if (isAcrescimoBruto) {
    acrescimo = valAcrescimo + (valTabela * perAcrescimo) / 100;
  } else {
    acrescimo = valAcrescimo + ((valTabela - desconto) * perAcrescimo) / 100;
  }
  return valTabela - desconto + acrescimo;
}
