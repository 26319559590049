/**
 * @solidus - CalcVendaVarejo
 */

export function calculaVendaVarejo(
  perPautaIVA: number,
  perPautaIVANCM: number,
  valPautaIVA: number,
  custoTotalSemST: number,
  qtdTotal: number,
  isPautaForn: boolean,
): number {
  const totalPautaIVA = valPautaIVA * qtdTotal;

  if (totalPautaIVA > custoTotalSemST && isPautaForn) {
    return totalPautaIVA;
  }

  let soma = 0;

  if (perPautaIVANCM !== 0) {
    soma = custoTotalSemST * (1 + perPautaIVANCM / 100);
  } else if (perPautaIVA !== 0) {
    soma = custoTotalSemST * (1 + perPautaIVA / 100);
  }
  return parseFloat(soma.toFixed(2));
}
