import api from '~/services/api';
import { Fornecedor, Produto, Loja, Tributacao } from '../../protocols';

export async function buscaTributacao(
  loja: Loja,
  fornecedor: Fornecedor,
  produto: Produto,
  tributacoes: Tributacao[],
): Promise<number> {
  if (loja.des_uf !== fornecedor.des_uf) {
    let codTributacao = 0;

    const res = await api.get('/entrada-nfe/tributacao-fornecedor', {
      params: {
        cod_ncm: produto.cod_ncm,
        des_sigla: fornecedor.des_uf,
        cod_tipo_item: produto.cod_tipo_item,
      },
    });

    const { success, data } = res.data;

    if (!success || !data) {
      codTributacao = produto.cod_trib_entrada;
    } else {
      codTributacao = data.cod_tributacao;
    }

    if (fornecedor.flg_regime_st) {
      if (produto.tipo_tributacao === 3) {
        const tributacaoEntrada = tributacoes.find(
          (tributacao: Tributacao) =>
            tributacao.cod_tributacao === codTributacao,
        );
        if (tributacaoEntrada) {
          const perICMSEnt = tributacaoEntrada.val_icms;
          const perRedEnt = tributacaoEntrada.val_reducao;
          const filteredTributacao = tributacoes.find(
            (tributacao: Tributacao) => {
              return (
                tributacao.val_icms === perICMSEnt &&
                tributacao.val_reducao === perRedEnt &&
                tributacao.tipo_tributacao === 3
              );
            },
          );
          if (filteredTributacao)
            codTributacao = filteredTributacao.cod_tributacao;
        }
      }
    }
    // fora do estado
    return codTributacao;
  }
  if (produto.cod_trib_entrada !== 0) return produto.cod_trib_entrada;
  return produto.cod_tributacao;
}
