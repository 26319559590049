import { calculaAliqIcmsRedBCEntrada } from '../Procedures/CalculaAliqIcmsRedBCEntrada';

export function calculaValorFCPST(
  valVendaVarejo: number,
  valAliqIcmsEntrada: number,
  perRedBCEntrada: number,
  valAliqIcmsEntradaNcm: number,
  perReducaoBCEntradaNcm: number,
  valAliqIcmsSaida: number,
  perReducaoBCSaida: number,
  perPautaIVANCM: number,
  valPautaIVA: number,
  valCustoEmbSemIPISTFrete: number,
  tipoTribEntrada: number,
  tipoTribSaida: number,
  isVedaCredito: boolean,
  isRegST: boolean,
  isFornIntrIcms: boolean, //
  isFornSimples: boolean,
  isInterestadual: boolean,
  perFCPSaida: number,
  perFCPEntrada: number,
): number {
  const infoTribEntrada = calculaAliqIcmsRedBCEntrada(
    valAliqIcmsEntrada,
    perRedBCEntrada,
    valAliqIcmsEntradaNcm,
    perReducaoBCEntradaNcm,
    valAliqIcmsSaida,
    perReducaoBCSaida,
    perPautaIVANCM,
    valPautaIVA,
    tipoTribEntrada,
    isVedaCredito,
    isRegST,
    isFornIntrIcms,
    isFornSimples,
    isInterestadual,
  );
  valAliqIcmsEntrada = infoTribEntrada.valAliqIcmsEntrada;
  perRedBCEntrada = infoTribEntrada.perRedBCEntrada;

  let valFCPCred: number;
  let soma = 0;

  const valBCSTEntrada = parseFloat(
    (valCustoEmbSemIPISTFrete * (1 - perRedBCEntrada / 100)).toFixed(4),
  );

  // M.E. e Simples tem B.C. igual a Zero, consequentemente não tem Crédito.

  if (isFornIntrIcms && tipoTribEntrada !== 3) {
    valFCPCred = 0;
  } else if (isFornSimples && !isInterestadual) {
    valFCPCred = valBCSTEntrada * (perFCPEntrada / 100);
  } else {
    valFCPCred = valBCSTEntrada * (perFCPEntrada / 100);
  }

  const valBCSTSaida = parseFloat(
    (valVendaVarejo * (1 - perReducaoBCSaida / 100)).toFixed(4),
  );

  const valFCPDeb = valBCSTSaida * (perFCPSaida / 100);

  if (tipoTribSaida === 3 && valFCPDeb !== 0) {
    soma = parseFloat((valFCPDeb - valFCPCred).toFixed(4));
  }

  return parseFloat(soma.toFixed(2));
}
