export function defineRegraImpostoCredito(
  isGeraPisCofinsIdCtb: boolean,
  isNaoPisCofins: boolean,
  isIntPisCofins: boolean,
  isNaoCredPisCofins: boolean,
): boolean {
  isNaoCredPisCofins = isNaoPisCofins;
  if (isIntPisCofins || isGeraPisCofinsIdCtb) {
    isNaoCredPisCofins = true;
  }
  return isNaoCredPisCofins;
}
