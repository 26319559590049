/**
 * @solidus - BaseCalculoReducao
 */

export function calculaReducaoBaseCalculo(
  valor: number,
  reducao: number,
): number {
  const baseCalculo = valor - valor * (reducao / 100);
  return parseFloat(baseCalculo.toFixed(2));
}
