import { calculaVariacao } from './CalculaVariacao';

export function validaTolerancia(
  valor: number,
  valor2: number,
  tolerancia: number,
): boolean {
  if (tolerancia === 0) return true;
  if (valor > 0 || valor2 > 0) {
    const variacao = calculaVariacao(valor, valor2);
    const percentual = Math.abs(variacao);
    if (percentual > tolerancia) return false;
  }
  return true;
}
