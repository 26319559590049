import { calculaValorIPI } from './ValorIPI';

/**
 * @solidus CalcValTabFinal
 * @param valTabela - VTabela
 * @param valTabelaLiq - VTabLiq
 * @param valST - VSubst
 * @param valIPI - VIPI
 * @param isIPIBR - IPIBr
 */

export function calculaValorTabelaFinal(
  valTabela: number,
  valTabelaLiq: number,
  valST: number,
  valIPI: number,
  isIPIBR: boolean,
): number {
  const ipi = calculaValorIPI(valTabela, valTabelaLiq, valIPI, 0, isIPIBR);
  return valTabelaLiq + ipi + valST;
}
