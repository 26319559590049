import { Cfop, Fornecedor, Loja, Produto, Tributacao } from '../../protocols';

export function defineCFOP(
  flgBonif: boolean,
  tributacao: Tributacao,
  produto: Produto,
  loja: Loja,
  fornecedor: Fornecedor,
  cfops: Cfop[],
): Cfop {
  const codIdCtb = produto.cod_id_ctb; // programar
  const flgVasilhame = false;
  let filtered;

  if (loja.uf === fornecedor.des_uf) {
    if (flgVasilhame) {
      filtered = cfops.filter(
        (cfop) =>
          cfop.cfop.indexOf('192') > -1 || cfop.cfop.indexOf('592') > -1,
      );
    } else if (flgBonif) {
      filtered = cfops.filter(
        (cfop) =>
          cfop.cfop.indexOf('191') > -1 || cfop.cfop.indexOf('592') > -1,
      );
    } else {
      filtered = cfops.filter(
        (cfop) =>
          cfop.cfop.startsWith('1') ||
          cfop.cfop.startsWith('5') ||
          cfop.cfop.startsWith('3'),
      );
    }
  } else if (flgVasilhame) {
    filtered = cfops.filter(
      (cfop) => cfop.cfop.indexOf('292') > -1 || cfop.cfop.indexOf('692') > -1,
    );
  } else if (flgBonif) {
    filtered = cfops.filter(
      (cfop) => cfop.cfop.indexOf('292') > -1 || cfop.cfop.indexOf('692') > -1,
    );
  } else {
    filtered = cfops.filter(
      (cfop) =>
        cfop.cfop.startsWith('2') ||
        cfop.cfop.startsWith('3') ||
        cfop.cfop.startsWith('6') ||
        cfop.cfop.startsWith('7'),
    );
  }

  if (tributacao.tipo_tributacao === 3) {
    filtered = filtered.filter((cfop) => {
      return cfop.flg_separa_subst || cfop.flg_dif;
    });
  } else {
    filtered = filtered.filter((cfop) => {
      return !cfop.flg_separa_subst;
    });
  }

  if (filtered.length >= 1) {
    if (tributacao.tipo_tributacao === 3) {
      const hasRegSeparaSubst = filtered.find(
        (filter) => filter.flg_separa_subst && filter.cod_id_ctb === codIdCtb,
      );
      if (hasRegSeparaSubst) {
        filtered = hasRegSeparaSubst;
      } else {
        const hasRegDif = filtered.find((filter) => filter.flg_dif);
        if (hasRegDif) {
          filtered = hasRegDif;
        }
      }
    } else {
      const hasRegSeparaSubst = filtered.find(
        (filter) => !filter.flg_separa_subst && filter.cod_id_ctb === codIdCtb,
      );
      if (hasRegSeparaSubst) {
        filtered = hasRegSeparaSubst;
      } else {
        const hasRegDif = filtered.find(
          (filter) => filter.flg_dif && filter.cod_id_ctb === codIdCtb,
        );
        if (hasRegDif) {
          filtered = hasRegDif;
        }
      }
    }
    if (filtered instanceof Array) {
      return filtered[0];
    }
    return filtered;
  }
  return cfops[0];
}
