import { calculaCustoTotSemIPIST } from './CustoTotalSemIPIST';
import { calculaTabelaLiquido } from './TabelaLiquido';
import { calculaValorRateioItemBruto } from './ValorRateioItemBruto';

/**
 * @Solidus CalcCustoTotSemSUBS
 * @param valCustoEmb - ValCustoEmb
 * @param qtdEntrada - QtdEnt
 * @param valDesconto - ValDesc
 * @param valAcrescimo - ValAcresc
 * @param valDespAcess - ValDespAcess
 * @param valIPIItem - ValIPIItem
 * @param valFreteItem - ValFreteITem
 * @param valFreteDestino - ValFreteDest
 * @param valTotalNF - ValTotalNF
 * @param isNaoDescontaBCST - BNaoDescBCST
 * @param isGuia - BGuia
 * @param isRegST - BRegST
 * @returns
 */

export function calculaCustoTotalSemST(
  valCustoEmb: number,
  qtdEntrada: number,
  valDesconto: number,
  valAcrescimo: number,
  valDespAcess: number,
  valIPIItem: number,
  valFreteItem: number,
  valFreteDestino: number,
  valTotalNF: number,
  isNaoDescontaBCST: boolean,
  isGuia: boolean,
  isRegST: boolean,
): number {
  let soma = 0;
  let valRateioFreteDest = 0;
  soma = calculaCustoTotSemIPIST(
    valCustoEmb,
    qtdEntrada,
    valDesconto,
    valAcrescimo,
    valDespAcess,
    valFreteItem,
    isNaoDescontaBCST,
  );
  soma = calculaTabelaLiquido(soma, 0, 0, valIPIItem, 0, false);

  if (valFreteDestino !== 0 && valTotalNF !== 0) {
    valRateioFreteDest = calculaValorRateioItemBruto(
      true,
      soma,
      valFreteDestino,
      valTotalNF,
    );
    soma += valRateioFreteDest;
  }

  // quando valor total nf for ZERO, deverá atribuir o valor já rateado na variável "ValFreteDest"

  if (isGuia && !isRegST && valFreteDestino !== 0 && valTotalNF === 0) {
    soma += valFreteDestino;
  }

  return soma;
}
