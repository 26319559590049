/**
 * Calcula o valor de rateio bruto entre os items
 * @solidus - ValRatItemBruto
 * @param isRateio - BRateia
 * @param valItem - ValItem
 * @param valRateio - ValRat
 * @param valTotalItem - ValTotItem
 */

export function calculaValorRateioItemBruto(
  isRateio: boolean,
  valItem: number,
  valRateio: number,
  valTotalItem: number,
): number {
  if (isRateio && valRateio > 0 && valTotalItem > 0) {
    return valItem * (valRateio / valTotalItem);
  }
  return 0;
}
