export function calculaPMZ(
  valCustoSemCred: number,
  valIcmsSaida: number,
  valDespesas: number,
  perPis: number,
  perCofins: number,
  isNaoPisCofins: boolean,
  perFCPIcms: number,
): number {
  let impostos = 0;
  if (isNaoPisCofins) {
    impostos = 1 / (1 - (valIcmsSaida + valDespesas + perFCPIcms) / 100);
  } else {
    impostos =
      1 /
      (1 -
        (valIcmsSaida + valDespesas + perPis + perCofins + perFCPIcms) / 100);
  }
  return parseFloat((valCustoSemCred * impostos).toFixed(3));
}
