/**
 * @solidus - CalculaValorIPI
 * @param valTabela - Tabela
 * @param valTabelaLiq - Tabela_Liq
 * @param valIPI - Val_IPI
 * @param perIPI - Per_IPI
 * @param isIPIBruto - IPI_Bruto
 * @returns
 */
export function calculaValorIPI(
  valTabela: number,
  valTabelaLiq: number,
  valIPI: number,
  perIPI: number,
  isIPIBruto: boolean,
): number {
  if (isIPIBruto) {
    return valIPI + (valTabela * perIPI) / 100;
  }
  return valIPI + (valTabelaLiq * perIPI) / 100;
}
