/**
 * @solidus - CalcValTabLiq
 */

export function calculaValorTabelaLiquida(
  valTabela: number,
  valDespAcess: number,
  valDesconto: number,
  valAcrescimo: number,
  valFrete: number,
): number {
  if (valTabela === 0) return 0;
  const valEncargos = valAcrescimo + valDespAcess + valFrete - valDesconto;
  return valTabela + valEncargos; // 268,35
}
