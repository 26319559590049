// import { Select } from '../protocols/Select';

type Select = {
  value: string | number;
  label: string;
};

export const unidades: Select[] = [
  {
    value: 'UN',
    label: 'UN',
  },
  {
    value: 'BD',
    label: 'BD',
  },
  {
    value: 'BJ',
    label: 'BJ',
  },
  {
    value: 'CJ',
    label: 'CJ',
  },
  {
    value: 'CT',
    label: 'CT',
  },
  {
    value: 'CX',
    label: 'CX',
  },
  {
    value: 'DS',
    label: 'DS',
  },
  {
    value: 'DZ',
    label: 'DZ',
  },
  {
    value: 'EN',
    label: 'EN',
  },
  {
    value: 'EB',
    label: 'EB',
  },
  {
    value: 'FD',
    label: 'FD',
  },
  {
    value: 'FR',
    label: 'FR',
  },
  {
    value: 'GR',
    label: 'GR',
  },
  {
    value: 'KG',
    label: 'KG',
  },
  {
    value: 'L3',
    label: 'L3',
  },
  {
    value: 'LA',
    label: 'LA',
  },
  {
    value: 'LT',
    label: 'LT',
  },
  {
    value: 'M2',
    label: 'M2',
  },
  {
    value: 'M3',
    label: 'M3',
  },
  {
    value: 'MC',
    label: 'MC',
  },
  {
    value: 'ML',
    label: 'ML',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'PC',
    label: 'PC',
  },
  {
    value: 'PT',
    label: 'PT',
  },
  {
    value: 'RL',
    label: 'RL',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'VS',
    label: 'VS',
  },
  {
    value: 'BO',
    label: 'BO',
  },
  {
    value: 'PE',
    label: 'PE',
  },
  {
    value: 'DP',
    label: 'DP',
  },
  {
    value: 'AG',
    label: 'AG',
  },
  {
    value: 'TP',
    label: 'TP',
  },
  {
    value: 'PR',
    label: 'PR',
  },
  {
    value: 'KWH',
    label: 'KWH',
  },
  {
    value: 'MWH',
    label: 'MWH',
  },
];
