// import { Select } from '../protocols/Select';

type Select = {
  label: string;
  value: number;
};

export const tipoEmbalagens: Select[] = [
  {
    label: 'Compra',
    value: 1,
  },
  {
    label: 'Venda',
    value: 0,
  },
];
