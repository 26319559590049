import { calculaTabelaLiquido } from './TabelaLiquido';

/**
 * @Solidus CalcCustoTotSemIPI_SUBS
 * @param valCustoEmb
 * @param qtdEntrada
 * @param valDesconto
 * @param valAcrescimo
 * @param valDespAcess
 * @param valFreteItem
 * @param isDescontaBCST
 * @returns
 */

export function calculaCustoTotSemIPIST(
  valCustoEmb: number,
  qtdEntrada: number,
  valDesconto: number,
  valAcrescimo: number,
  valDespAcess: number,
  valFreteItem: number,
  isDescontaBCST: boolean,
): number {
  let soma = 0;
  if (valCustoEmb === 0 || qtdEntrada === 0) return 0;

  soma = calculaTabelaLiquido(
    valCustoEmb * qtdEntrada,
    isDescontaBCST ? 0 : valDesconto,
    0,
    valAcrescimo,
    0,
    false,
  );
  soma = calculaTabelaLiquido(soma, 0, 0, valFreteItem, 0, false);
  soma = calculaTabelaLiquido(soma, 0, 0, valDespAcess, 0, false);
  return soma;
}
