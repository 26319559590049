import { validaTolerancia } from '../Auxiliares/ValidaTolerancia';

/**
 * @solidus CalcCustoMedio
 * @param valCustoRepAtual - CustoRepAtual
 * @param valCustoMedAnt - CustoMedAnt
 * @param qtdAnt - QtdeAnt
 * @param qtdAtual - QtdAtual
 */

export function calculaCustoMedio(
  valCustoRepAtual: number,
  valCustoMedAnt: number,
  qtdAnt: number,
  qtdAtual: number,
): number {
  const totalAtual = valCustoRepAtual * qtdAtual;
  const totalAnt = valCustoMedAnt * qtdAnt;

  if (qtdAnt < 0) {
    return valCustoRepAtual;
  }

  try {
    const soma = Math.abs((totalAtual + totalAnt) / (qtdAnt + qtdAtual));
    const tolerancia = validaTolerancia(soma, valCustoMedAnt, 50);
    if (!tolerancia) {
      if (soma > valCustoRepAtual * 10000) {
        return valCustoRepAtual;
      }
    }
    return soma;
  } catch (error) {
    return Math.abs(valCustoRepAtual);
  }
}
